@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  width: 100%;
}

.dropdown {
  overflow: hidden;
  height: 0px;
  transition: height 0.3s ease;
}

.dropdown-active {
  height: 10rem;
  overflow: auto;
  transition: height 0.3s ease;
}

.nav-elem {
  transition: ease-in-out 0.15s;
}

.nav-elem:hover {
  text-shadow: 2px 5px 5px #909090;
}

.show-cursor {
  cursor: pointer;
}

.button-link {
  opacity: 0.9;
  background: rgb(59 130 246);
  transition: 0.3s;
}

.button-link:hover {
  background: rgb(37 99 235);
  opacity: 1;
}

.footer-link {
  font-weight: 400;
  opacity: 0.5;
  transition: 0.8s;
}

.footer-link:hover {
  opacity: 1;
}
